export default {
    components: {},
    created() {
        window.scroll(0, 0);
        this.GetContents();
        this.GetProducers()
    },
    data() {
        return {
            producers: [],
            spchelpruducer: {},
            gols: {},
            service: {},
            hero: {},

        };
    },
    methods: {
        GetContents() {
            this.$http.contentsService
                .GetContents()
                .then((res) => {
                    this.hero = res.data.result.hero;
                    this.service = res.data.result.service;
                    this.gols = res.data.result.gols;
                    this.spchelpruducer = res.data.result.spchelpruducer;
                    // this.producers = res.data.result.producers;

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        GetProducers() {
            this.$http.contentsService
                .GetProducers()
                .then((res) => {
                    this.producers = res.data.result.producersQ;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};